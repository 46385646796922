import React, { useState, useRef, useEffect } from 'react';

const NODE_WIDTH = 300;
const NODE_HEIGHT = 150;

const Graph = () => {
  const wrapText = (text, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = getTextWidth(currentLine + " " + word);

      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const getTextWidth = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '14px Arial';
    return context.measureText(text).width;
  };

  const Node = ({ x, y, onClick, isSelected, story, isLoading }) => {
    const lines = wrapText(story, NODE_WIDTH - 20);

    return (
      <g onClick={onClick}>
        <rect
          x={x - NODE_WIDTH / 2}
          y={y - NODE_HEIGHT / 2}
          width={NODE_WIDTH}
          height={NODE_HEIGHT}
          fill={isSelected ? "orange" : "white"}
          stroke="black"
          strokeWidth={2}
        />
        {isLoading ? (
          <text x={x} y={y} textAnchor="middle" fontSize="14" fill="black">
            Loading...
          </text>
        ) : (
          lines.map((line, index) => (
            <text
              key={index}
              x={x}
              y={y - NODE_HEIGHT / 2 + 25 + index * 20}
              textAnchor="middle"
              fontSize="14"
              fill="black"
            >
              {line}
            </text>
          ))
        )}
      </g>
    );
  };

  const Edge = ({ start, end, label }) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    let angle = Math.atan2(dy, dx) * 180 / Math.PI;

    let flip = false;
    if (angle < -90 || angle > 90) {
      flip = true;
      angle += 180;
    }

    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;

    return (
      <g>
        <line
          x1={start.x}
          y1={start.y}
          x2={end.x}
          y2={end.y}
          stroke="black"
          strokeWidth={2}
        />
        <g transform={`translate(${midX}, ${midY}) rotate(${angle}) ${flip ? 'scale(-1, -1)' : ''}`}>
          <text
            x={0}
            y={-10}
            textAnchor="middle"
            fill="black"
            fontSize="12"
          >
            {label}
          </text>
        </g>
      </g>
    );
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
          {onClose && (
            <button
              onClick={onClose}
              className="float-right text-gray-500 hover:text-gray-700 text-2xl"
            >
              ×
            </button>
          )}
          {children}
        </div>
      </div>
    );
  };

  const [nodes, setNodes] = useState([{ id: 0, x: 0, y: 0, left: null, middle: null, right: null, story: "", options: [], isLoading: false }]);
  const [edges, setEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(nodes[0]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [viewBox, setViewBox] = useState({ x: -600, y: -450, width: 1200, height: 900 });
  const [error, setError] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isPanning, setIsPanning] = useState(false);
  const [startPan, setStartPan] = useState({ x: 0, y: 0 });
  const [storyStarted, setStoryStarted] = useState(false);
  const [theme, setTheme] = useState("");
  const svgRef = useRef(null);

const findParentNode = (nodeId) => {
  return nodes.find(n => n.left === nodeId || n.middle === nodeId || n.right === nodeId);
};

const fetchStory = async (node = null) => {
  try {
    let prompt;
    if (!node) {
      prompt = `Write a short story (max 3 lines) in the theme of "${theme}" that leads to a point where the user should make a choice. Provide 3 options for the choice. Return in JSON format: {story: "the story", options: ["option1", "option2", "option3"]}`;
    } else {
      // Collect the full story context
      const storyContext = [];
      let currentNode = node;
      while (currentNode) {
        if (currentNode.story) {
          storyContext.unshift(`Story: ${currentNode.story}`);
        }
        if (currentNode.choice) {
          storyContext.unshift(`Choice made: ${currentNode.choice}`);
        }
        currentNode = findParentNode(currentNode.id);
      }

      const fullContext = storyContext.join("\n");

      prompt = `Given the following story context and theme "${theme}":\n\n${fullContext}\n\nContinue the story based on the last choice made, keeping in mind the overall theme. Write a short story (max 3 lines) that leads to a point where the user should make a choice. Provide 3 options for the choice. Return in JSON format: {story: "the story", options: ["option1", "option2", "option3"]}`;
    }

    const response = await fetch('https://late-art-4a59.arashparnia.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    setError('Failed to fetch story. Please try again.');
    return null;
  }
};
  const handleNodeClick = async (node) => {
    if (node.isLoading) return;
    setSelectedNode(node);
    if (node.story && node.options.length > 0) {
      setIsDialogOpen(true);
    } else if (node.left === null && node.middle === null && node.right === null) {
      setNodes(nodes.map(n => n.id === node.id ? { ...n, isLoading: true } : n));
      const data = await fetchStory(node);
      if (data) {
        const updatedNodes = nodes.map(n =>
          n.id === node.id ? { ...n, story: data.story, options: data.options, isLoading: false } : n
        );
        setNodes(updatedNodes);
        setSelectedNode({ ...node, story: data.story, options: data.options, isLoading: false });
      } else {
        setNodes(nodes.map(n => n.id === node.id ? { ...n, isLoading: false } : n));
      }
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleChoice = (direction, index) => {
    setIsDialogOpen(false);
    if (selectedNode[direction] !== null) return;

    const newNodeId = nodes.length;
    let offset;
    switch(direction) {
      case 'left':
        offset = -400;
        break;
      case 'middle':
        offset = 0;
        break;
      case 'right':
        offset = 400;
        break;
      default:
        offset = 0;
    }

    const newNode = {
      id: newNodeId,
      x: selectedNode.x + offset,
      y: selectedNode.y - 250,
      left: null,
      middle: null,
      right: null,
      story: "",
      options: [],
      choice: selectedNode.options[index],
      isLoading: false
    };

    const updatedNodes = nodes.map(node =>
      node.id === selectedNode.id ? { ...node, [direction]: newNodeId } : node
    );

    setNodes([...updatedNodes, newNode]);
    setEdges([...edges, { start: selectedNode, end: newNode, label: selectedNode.options[index] }]);
    setSelectedNode(newNode);
  };

const handleZoom = (delta, clientX, clientY) => {
  setZoom(prevZoom => {
    const newZoom = Math.max(0.1, Math.min(5, prevZoom * (1 + delta)));

    const svgRect = svgRef.current.getBoundingClientRect();
    const mouseX = clientX - svgRect.left;
    const mouseY = clientY - svgRect.top;

    // Convert mouse position to SVG coordinates
    const svgX = viewBox.x + (mouseX / svgRect.width) * viewBox.width;
    const svgY = viewBox.y + (mouseY / svgRect.height) * viewBox.height;

    // Calculate new viewBox dimensions
    const newWidth = viewBox.width / newZoom * prevZoom;
    const newHeight = viewBox.height / newZoom * prevZoom;

    // Calculate new viewBox position to keep the mouse point fixed
    const newX = svgX - (mouseX / svgRect.width) * newWidth;
    const newY = svgY - (mouseY / svgRect.height) * newHeight;

    setViewBox({
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight
    });

    return newZoom;
  });
};

  const handleWheel = (e) => {
  e.preventDefault();
  const delta = -e.deltaY * 0.0005;
  handleZoom(delta, e.clientX, e.clientY);
};

  const handleMouseDown = (e) => {
    if (e.target.tagName === 'svg') {
      setIsPanning(true);
      setStartPan({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
  if (isPanning) {
    const dx = (e.clientX - startPan.x);
    const dy = (e.clientY - startPan.y);
    setViewBox(vb => ({
      ...vb,
      x: vb.x - dx / zoom,
      y: vb.y - dy / zoom
    }));
    setStartPan({ x: e.clientX, y: e.clientY });
  }
};

  const handleMouseUp = () => {
    setIsPanning(false);
  };

 const startStory = async () => {
  if (!theme.trim()) {
    setError("Please enter a theme for your story.");
    return;
  }
  setStoryStarted(true);
  const data = await fetchStory();
  if (data) {
    const updatedNodes = nodes.map(n =>
      n.id === 0 ? { ...n, story: data.story, options: data.options, isLoading: false } : n
    );
    setNodes(updatedNodes);
    setSelectedNode(updatedNodes[0]);
  }
};
  useEffect(() => {
    const updateViewBox = () => {
      const padding = 200;
      const minX = Math.min(...nodes.map(n => n.x)) - padding - NODE_WIDTH / 2;
      const maxX = Math.max(...nodes.map(n => n.x)) + padding + NODE_WIDTH / 2;
      const minY = Math.min(...nodes.map(n => n.y)) - padding - NODE_HEIGHT / 2;
      const maxY = Math.max(...nodes.map(n => n.y)) + padding + NODE_HEIGHT / 2;
      const width = (maxX - minX) / zoom;
      const height = (maxY - minY) / zoom;

      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;

      setViewBox({
        x: centerX - width/2,
        y: centerY - height/2,
        width,
        height
      });
    };

    updateViewBox();
  }, [nodes, zoom]);

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100 relative">
      {!storyStarted ? (
          <Modal isOpen={true}>
            <h2 className="text-2xl font-bold mb-6">Start Your Story</h2>
            <p className="mb-4">Enter a theme for your story:</p>
            <input
                type="text"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="e.g., Space Adventure, Medieval Fantasy, etc."
                autoFocus
            />
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
                onClick={startStory}
                className="px-6 py-3 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-600"
            >
              Start Story
            </button>
          </Modal>
      ) : (
          <>
            <svg
                ref={svgRef}
                width="100%"
                height="100%"
                viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
                style={{border: '1px solid black' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onWheel={handleWheel}
          >
            {edges.map((edge, index) => (
              <Edge key={index} start={edge.start} end={edge.end} label={edge.label} />
            ))}
            {nodes.map((node) => (
              <Node
                key={node.id}
                x={node.x}
                y={node.y}
                onClick={() => handleNodeClick(node)}
                isSelected={node.id === selectedNode.id}
                story={node.story}
                isLoading={node.isLoading}
              />
            ))}
          </svg>
          <div className="absolute top-4 right-4 flex space-x-2">
            <button onClick={() => handleZoom(0.1, window.innerWidth / 2, window.innerHeight / 2)} className="px-4 py-2 bg-blue-500 text-white rounded">Zoom In</button>
            <button onClick={() => handleZoom(-0.1, window.innerWidth / 2, window.innerHeight / 2)} className="px-4 py-2 bg-blue-500 text-white rounded">Zoom Out</button>
          </div>
  <Modal isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <h2 className="text-2xl font-bold mb-6">Story Progression</h2>
            {error ? (
              <p className="text-red-500 text-xl">{error}</p>
            ) : (
              <>
                <div className="my-6 p-6 bg-gray-100 rounded-md">
                  <p className="text-xl text-gray-800">{selectedNode.story}</p>
                </div>
                {selectedNode.options.length > 0 && (
                  <div className="flex flex-col space-y-4 mt-6">
                    <h3 className="text-xl font-semibold">What will you do?</h3>
                    {selectedNode.options.map((option, index) => (
                      <button
                        key={index}
                        onClick={() => handleChoice(['left', 'middle', 'right'][index], index)}
                        disabled={selectedNode[['left', 'middle', 'right'][index]] !== null}
                        className="px-6 py-3 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-600 disabled:opacity-50"
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default Graph;